import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import dataplatformGraphicImage from '../resources/images/dataplatform-graphic.png'
import ogImage from '../resources/images/we-are-hiring.jpeg'

const dataplatformGraphic = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Dataplatform Graphic</title>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <img
        style={{ width: '100vw', overflowX: 'hidden' }}
        src={dataplatformGraphicImage}
        alt="Dataplatform graphic"
      />
    </Fragment>
  )
}

export default dataplatformGraphic
